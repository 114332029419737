import axios from 'axios';
import { useState } from 'react';

function Upload() {
  const [image, setImage] = useState(null);
  const [url, setURL] = useState('');

  const handleUpload = async (event) => {
    event.preventDefault();

    // Créer un objet FormData pour stocker les données de l'image
    const formData = new FormData();
    formData.append('file', image);
    console.log(image);
    // Envoyer la requête POST à l'API NFT.Storage pour télécharger l'image
    const response = await axios.post('https://api.nft.storage/upload', formData, {
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDZBREE5OGI2Njk0RDdENGQ5MjdEMzczODExMzlDYmU2ZWYxMmIzMjciLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODAyNzk3NjQ1OSwibmFtZSI6IlNpbmdsZSBSZWFjdEpTIFVwbG9hZCJ9.xC0-Gy6V4XbrmpTRmZDLSoqFOXI8UVekzY8S9hU8rUU',
        'Content-Type': 'multipart/form-data'
      }
    });

    // Récupérer le CID de l'image à partir de la réponse
    console.log (response);
    const cid = response.data.value.cid;
    const filename = response.data.value.files[0].name;
    setURL('ipfs://'+cid+'/'+filename);
    console.log('Image uploaded to IPFS with CID:', cid);
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <div>
    
      <h1>Upload d'une image sur IPFS via NFT.Storage</h1>
      {url.length > 0 && <p>Voici votre lien : <a href={url}>{url}</a></p>}
      <form onSubmit={handleUpload}>
        <label>
          Choisissez une image :
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </label>
        <button type="submit">Télécharger</button>
      </form>
    </div>
  );
}

export default Upload;