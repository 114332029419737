import './App.css';

function QuatreCentQuatre() {
    return  <div className="App">
                <header className="App-header">
                    <div className="QuatreCentQuatre">404</div>
                </header>
            </div>;
}

export default QuatreCentQuatre;