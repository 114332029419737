import './App.css';
import './Collection.css';
import React from 'react';
import { useState, useEffect } from "react";
import { useAccount } from 'wagmi'
import { Web3Button } from "@web3modal/react";
import { useContractRead } from 'wagmi';
import { readContract } from '@wagmi/core';

// contract abis
import MariposaAbi from "../assets/MariposaAbi.json";

// contracts addresses :
const MariposaContract = process.env.REACT_APP_MARIPOSA_CONTRACT;

function ArtCollectionForm(props) {
  const [name, setName] = useState(props.artwork.name);
  const [description, setDescription] = useState(props.artwork.name);
  const [artist, setArtist] = useState(props.artwork.artist);
  const [uri, setUri] = useState(props.artwork.uri);
  const [legendary, setLegendary] = useState(props.artwork.legendary);

  useEffect(() => {
    setName(props.artwork.name);
    setDescription(props.artwork.description);
    setArtist(props.artwork.artist);
    setUri(props.artwork.uri);
    setLegendary(props.artwork.legendary);
  }, [props.artwork]);

  const handlePrev = (e) => {
    e.preventDefault();
    props.onPrev();
  };

  const handleNext = (e) => {
    e.preventDefault();
    props.onNext();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedArtwork = {
      name: name,
      description: description,
      artist: artist,
      uri: uri,
      legendary: legendary
    };
    console.log(updatedArtwork);
    props.onUpdate(updatedArtwork);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <div>
          <label>Name</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label>Descr</label>
          <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div>
          <label>Artist</label>
          <input type="text" value={artist} onChange={(e) => setArtist(e.target.value)} />
        </div>
        <div>
          <label>Uri</label>
          <input type="text" value={uri} onChange={(e) => setUri(e.target.value)} />
        </div>
        <div>
          <label>Legend</label>
          <input type="checkbox" checked={legendary ? "checked" : ""} onChange={(e) => setLegendary(e.target.checked)} />
        </div>
        <div className="nav">
          <a className="nav" alt="prev" href="#" onClick={handlePrev}>&lt;</a>
          <button className="nav" type="submit">Valider</button>
          <a className="nav" alt="next" href="#" onClick={handleNext}>&gt; </a>
        </div>
      </form>
      <div className="uripreview">
        <img
          src={props.artwork.uri.replace(
            "ipfs://",
            "https://nftstorage.link/ipfs/"
          )}
          alt={props.artwork.name}
        />
      </div>
    </div>
  );
}

class ArtCollectionFormWrapper extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      maxId: props.projectInfo.maxId,
      isFetched: false,
      selectedArtworkIndex: 1,
      artworks: []
    };
  }

  async collectionFetcher(_maxId) {
    let tmpArtworks = [];
    for (let i = 1 ; i <= _maxId ; i++) {
      try {
        const data = await readContract({
          address: MariposaContract,
          abi: MariposaAbi,
          functionName: 'MRPSitems',
          chainId: parseInt(process.env.REACT_APP_CHAINID),
          args: [i]
        });
        tmpArtworks[i]=data;
      } catch (e) {
        console.log(e);
        return false;
      }  
    }
    return tmpArtworks;
  }
    
  async componentDidMount () {
    const data = await this.collectionFetcher(this.state.maxId);
    this.setState ({
      artworks: data,
      isFetched: true
    });  
  }

  // fonction pour mettre à jour les données de l'oeuvre sélectionnée
  handleArtworkUpdate = (updatedArtwork) => {
    let _data = this.state.artworks;
    _data[this.state.selectedArtworkIndex] = updatedArtwork;
    this.setState({artworks: _data});
  };
  
  // fonction pour passer à l'oeuvre suivante dans le formulaire
  handleNextArtwork = () => {
    this.setState({selectedArtworkIndex: this.state.selectedArtworkIndex === this.state.maxId ? 1 : this.state.selectedArtworkIndex + 1 });
  };

  // fonction pour passer à l'oeuvre précédente dans le formulaire
  handlePreviousArtwork = () => {
    this.setState({selectedArtworkIndex: this.state.selectedArtworkIndex === 1 ? this.state.maxId : this.state.selectedArtworkIndex - 1 });
  };

  render() {
    if (this.state.isFetched) {
      console.log(this.state);
      return  <><div>{this.state.selectedArtworkIndex} / {this.state.maxId}</div>
                <ArtCollectionForm
                  artwork={this.state.artworks[this.state.selectedArtworkIndex]}
                  onUpdate={this.handleArtworkUpdate}
                  onNext={this.handleNextArtwork}
                  onPrev={this.handlePreviousArtwork}
                />
              </>
    } else {
      return <>
          <div>Récupération des données</div>
          <img className="loading" src="/loading.gif" alt="loading" />
        </>;
    }
  }
}

function Collection() {
  const { address, isConnected } = useAccount()
  const [ownerAddress, setOwnerAddress] = useState(0);
  const [projectInfo, setProjectInfo] = useState(false);

  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'owner',
    onSuccess(data) {
      setOwnerAddress(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })
  
  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'MRPSProject',
    onSuccess(data) {
      setProjectInfo(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })
  
  return(
    <>
      <div className={ isConnected ? "ConnBtnDiv-connected" : "" }><Web3Button /></div>
      { isConnected &&
      <>
        <div className="Dashboard-title">Collection</div>
        { projectInfo !== false && 
            ownerAddress === address && 
            <div className="Collection">
              <ArtCollectionFormWrapper projectInfo={projectInfo} />
            </div>
        }
      </>
      }
    </>
  );
}

export default Collection;
