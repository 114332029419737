import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './pages/App';
import Layout from './pages/Layout';
import Dashboard from './pages/Dashboard';
import Emergence from './pages/Emergence';
import AdminPanel from './pages/Admin';
import Collection from './pages/Collection';
import Upload from './pages/Upload';
import QuatreCentQuatre from './pages/QuatreCentQuatre';
import reportWebVitals from './reportWebVitals';

export default function Skel() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Emergence />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="emergence" element={<Emergence />} />
          <Route path="admin" element={<AdminPanel />} />
          <Route path="admin/collection" element={<Collection />} />
          <Route path="admin/upload" element={<Upload />} />
          <Route path="*" element={<QuatreCentQuatre />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Skel />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
