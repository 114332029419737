import './Admin.css';
import { ethers } from "ethers";
import { useState } from "react";
import { useAccount } from 'wagmi'
import { Web3Button } from "@web3modal/react";
import { useContractRead } from 'wagmi';
import { prepareWriteContract, writeContract } from '@wagmi/core';

// contract abis
import ERC20Abi from "../assets/ERC20Abi.json";
import MariposaAbi from "../assets/MariposaAbi.json";

// contracts addresses :
const WETHContract = process.env.REACT_APP_WETH_CONTRACT;
const MariposaContract = process.env.REACT_APP_MARIPOSA_CONTRACT;

async function contractSetStep(toStep) {
  try {
    const config = await prepareWriteContract({
      address: MariposaContract,
      abi: MariposaAbi,
      functionName: 'setStep',
      chainId: parseInt(process.env.REACT_APP_CHAINID),
      args: [toStep]
    })
    const ret = await writeContract(config);
    await ret.wait(1);
  } catch (e) {
    console.log(e);
    return false;
  }
  return toStep;
}

async function contractSetPrice(newPrice) {
  const bnMintPrice = ethers.utils.parseEther (newPrice).toString();
  try {
    const config = await prepareWriteContract({
      address: MariposaContract,
      abi: MariposaAbi,
      functionName: 'setMintPrice',
      chainId: parseInt(process.env.REACT_APP_CHAINID),
      args: [bnMintPrice]
    })
    const ret = await writeContract(config);
    await ret.wait(1);
  } catch (e) {
    console.log(e);
    return false;
  }
  return newPrice;
}

function AdminPanel() {
    const { address, isConnected } = useAccount()
    const [projectInfo, setProjectInfo] = useState(false);
    const [symbol, setSymbol] = useState('WETH');
    const [balance, setBalance] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [isBank, setIsBank] = useState(false);

    function StepChooser(props) {
      const [step, setStep] = useState(props.activeStep);
      const [loading, setLoading] = useState(false);
    
      async function activateStep(e) {
        e.preventDefault();
        if (!isAdmin) {
          alert('Need NFT role');
          return;
        }
        if (step != e.target.value) {
          setLoading(true);
          const result = await contractSetStep(e.target.value);
          if (result !== false) setStep(e.target.value);
          setLoading(false);
        }
      }
      
      return <div className="Admin-StepChooser">
      <div className="Admin-StepChooser-Title">Phase de Mint</div>
      {loading ?
        <img className="loading" src="/loading.gif" alt="loading" /> :
        <>
          <button className={step == 0 ? "activestep" : ""} value="0" onClick={activateStep}>Non ouvert</button>
          <button className={step == 1 ? "activestep" : ""} value="1" onClick={activateStep}>Whitelists</button>
          <button className={step == 2 ? "activestep" : ""} value="2" onClick={activateStep}>Mint public</button>
          <button className={step == 3 ? "activestep" : ""} value="3" onClick={activateStep}>Soldout</button>
        </>
      }
    </div>
    }
    
    function MintPrice(props) {
      console.log(props);
      const [loading, setLoading] = useState(false);
      const [editing, setEditing] = useState(false);
      const [isError, setIsError] = useState(false);
      const [mintPriceStr, setMintPriceStr] = useState(ethers.utils.formatEther(props.projectInfo.mintPrice));
    
      async function changeMintPrice(e) {
        e.preventDefault();
        const data = new FormData(e.target);
    
        const newPrice = data.get("newPrice");
        setEditing(false);
        setLoading(true);
        const result = await contractSetPrice(newPrice);
        if (result !== false) setMintPriceStr(newPrice);
        setIsError(result === false);
        setLoading(false);
      }
      
      function setEditingTrue() {setEditing(true);}
      function cancelEditing() {setEditing(false);}
    
      return <div className="Admin-StepChooser">
      <div className="Admin-StepChooser-Title">Prix du Mint</div>
      { loading && <img className="loading" src="/loading.gif" alt="loading" /> }
      { editing && 
        <form onSubmit={changeMintPrice}>
          <input type="text" name="newPrice" defaultValue={mintPriceStr} /> 
          <button type="submit">Changer</button>
          <button onClick={cancelEditing}>Conserver</button>
        </form>
      }
      { ! (loading || editing ) && <div className="MintPriceStr" onClick={setEditingTrue}>{mintPriceStr} {props.symbol}</div>}
      { isError && "Une erreur est survenue !" }
      </div>
    }
    
    useContractRead({
      address: MariposaContract,
      abi: MariposaAbi,
      functionName: 'hasRole',
      args: [process.env.REACT_APP_NFT_ROLE, address],
      onSuccess(data) {
        console.log('IsAdmin : ', data);
        setIsAdmin(data);
      },
      onError(error) {
        console.log('Error', error);
      },
    })
    
    useContractRead({
      address: MariposaContract,
      abi: MariposaAbi,
      functionName: 'hasRole',
      args: [process.env.REACT_APP_BANK_ROLE, address],
      onSuccess(data) {
        console.log('IsBank : ', data);
        setIsBank(data);
      },
      onError(error) {
        console.log('Error', error);
      },
    })
   
    // Get project information
    useContractRead({
        address: MariposaContract,
        abi: MariposaAbi,
        functionName: 'MRPSProject',
        onSuccess(data) {
            setProjectInfo(data);
        },
        onError(error) {
          console.log('Error', error);
        },
    })

    // Get WETH symbol
    useContractRead({
      address: WETHContract,
      abi: ERC20Abi,
      functionName: 'symbol',
      onSuccess(data) {
        setSymbol(data);
      },
      onError(error) {
        console.log('Error', error);
      },
    })
  
    // Get contract's WETH Balance
    useContractRead({
      address: WETHContract,
      abi: ERC20Abi,
      functionName: 'balanceOf',
      args: [MariposaContract],
      onSuccess(data) {
        setBalance(ethers.utils.formatEther(data));
      },
      onError(error) {
        console.log('Error', error);
      },
    })
    
    return(
      <>
        <div className={ isConnected ? "ConnBtnDiv-connected" : "" }><Web3Button /></div>
        { isConnected &&
          ( isAdmin || isBank ) &&
          <>
          { projectInfo !== false && 
              <div className="Admin-panel">
                  <div className="Admin-title">Admin</div>
                  <div>Balance : {balance} {symbol}</div>
                  <StepChooser activeStep={projectInfo.step} />
                  <MintPrice projectInfo={projectInfo} symbol={symbol} />
              </div>
          }
        </>
      }
      </>
    );
  }
  
  export default AdminPanel;
  