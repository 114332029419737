import './App.css';
import { useState } from "react";
import { useAccount } from 'wagmi';
import { Web3Button } from "@web3modal/react";
import { useContractRead, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { ethers } from "ethers";

import WLProofs from "../assets/proofs.json";

// contract abis
import MariposaAbi from "../assets/MariposaAbi.json";
import MariposaRevealedAbi from "../assets/MariposaRevealedAbi.json";

// contracts addresses :
const MariposaContract = process.env.REACT_APP_MARIPOSA_CONTRACT;
const MariposaRevealedContract = process.env.REACT_APP_MARIPOSAREVEALED_CONTRACT;

// Step 0 : Non connecté --> Connect
//      1 : Connecté  --> CheckBalance
//      2 : Balance suffisante --> Approval
//      3 : Approval OK --> Burn & MINT
//      4 : NFT Minté --> Success
let step = 0;

function Imago(props) {
  const { address } = useAccount()
  const [burnID, setBurnID] = useState(0);
  const [approval, setApproval] = useState(false);

  useContractRead({
    address: MariposaRevealedContract,
    abi: MariposaRevealedAbi,
    functionName: 'userIsEligibleForId',
    args: [address],
    onSuccess(data) {
      setBurnID(parseInt(data));
    },
    onError(error) {
      console.log('Error', error);
    },
  })

  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'isApprovedForAll',
    args: [address, MariposaRevealedContract],
    onSuccess(data) {
      setApproval(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })

  function WaitMintTransaction (props) {
    const { isError, isLoading } = useWaitForTransaction({
      hash: props.transaction,
      confirmations: 1,
      onError(error) {
        console.log('Error', error)
      },
      onSuccess(data) {
        step = 4;
      },
  
    })
    if (isLoading) return <div><img className="loading" src="/loading.gif" alt="loading" /></div>
    if (isError) return <div>Une erreur est survenue</div>
  
    return <>{ step === 4 &&
      <>
      <div>Félicitations !<br />Emergence réussie !</div>
      <div className="BFRpreview"><img src={props.uriPreview} alt="Aperçu du papillon" /></div>
      </>
    }</>;
  }
  
  function WaitApprovalTransaction (props) {
    const { isError, isLoading } = useWaitForTransaction({
      hash: props.transaction,
      confirmations: 1,
      onError(error) {
        console.log('Error', error)
      },
      onSuccess(data) {
        step = 3;
      }  
    })
    if (isLoading) return <div><img className="loading" src="/loading.gif" alt="loading" /></div>
    if (isError) return <div>Une erreur est survenue</div>
  
    return <div>{ step === 3 && <div><MintButton burnID={burnID} /></div> }</div>;
  }
    
  function MintButton (props) {
    const [message, setMessage] = useState('');
    const [uriPreview, setURIPreview] = useState('');

    useContractRead({
      address: MariposaRevealedContract,
      abi: MariposaRevealedAbi,
      functionName: 'MRPSitems',
      args: [props.burnID],
      onSuccess(data) {
        setURIPreview(data.uri.replace("ipfs://", "https://nftstorage.link/ipfs/"));
      },
      onError(error) {
        console.log('Error', error);
      },
    })
  
    const { data, isLoading, isSuccess, write } = useContractWrite({
      address: MariposaRevealedContract,
      abi: MariposaRevealedAbi,
      functionName: 'publicReveal',
      args: [props.burnID],
      onError(error) {
        console.log('Error', error);
        setMessage('Mint impossible');
      },
    });

    return (
      <div>
        <p>Etape 2/2 : Révélez votre papillon</p>
        { (!isSuccess) && (!isLoading) && <div><button onClick={() => write?.()}>Emergence</button></div> }
        { isLoading && <div>Autorisez la transaction dans votre wallet</div> }
        { isSuccess && <div><WaitMintTransaction transaction={data.hash} uriPreview={uriPreview} /></div> }
      </div>
    );
  }
  
  function ApproveButton(props) {
    const { config } = usePrepareContractWrite({
      address: MariposaContract,
      abi: MariposaAbi,
      functionName: 'setApprovalForAll',
      args: [MariposaRevealedContract, true],
        onError(error) {
        console.log('Error', error);
      }
    })
    const { data, isLoading, isSuccess, write } = useContractWrite(config);
  
    return (
      <div>
        { !isSuccess && !isLoading && <p>Etape 1/2 : Autoriser l'ouverture de votre chrysalide (n°{burnID})</p> }
        { !isSuccess && !isLoading && <button disabled={!write} onClick={() => write?.()}>Approuver {props.mintPrice} {props.symbol}</button> }
        { isLoading && "Autorisez la transaction dans votre wallet" }
        { isSuccess && <div><WaitApprovalTransaction transaction={data.hash} /></div> }
      </div>
    );
  }
    
  if (burnID > 0) { 
    if (approval) {
      step = 3;
    } else {
      step = 2;
    }
  } else {
    step = 1;
  }

  return <>
    <div className="mintpriceinfos">
      <div className="projectstepinfo">
      Phase en cours : Emergence
      </div> 
    </div>
    <div className="actionbuttons">
      { step === 1 && 
        <>Vous n'avez pas de chrysalide<br />à révéler actuellement</>
      }
      { step === 2 && 
        <div>
            <ApproveButton />
          </div>
      }
      { step === 3 &&
        <div>
          <MintButton burnID={burnID} />
        </div> }
    </div>
  </>;
}

function Emergence() {
  const { address, isConnected } = useAccount()

  if (isConnected) {
      step=1;
  }

  return(
    <>
      <div className={ isConnected ? "ConnBtnDiv-connected" : "" }><Web3Button /></div>
      { step >= 1 && step <= 3 && isConnected && <Imago /> }
    </>
  );
}

export default Emergence;
